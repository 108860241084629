import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";

// Components
import SEO from "../../../components/seo/seo";
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../../components/contact-info/contact-info";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";
import DebitCardsOpenAccount from "../../../components/sign-up/debit-cards-open-account";
import VimeoVideoFrame from "../../../components/custom-widgets/vimeo-video-frame";
import FeaturesSection from "../../../components/rewards-checking/features-section";
import ServiceStatesOpenAccountSection from "../../../components/service-states/service-states-open-account-section";
import StartSavingsSection from "../../../components/start-savings-section/start-savings-section";


const SpanishFreeChecking = () => {
  const title = "Cuenta de cheques gratuita",
    description =
      "Descubra la cuenta de cheques gratuita de WaFd Bank que es verdaderamente gratuita y sin condiciones. Abra una cuenta en línea hoy y vea por qué fuimos votados como el mejor banco.";
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/free-checking-account/hero-free-checking-061124-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/free-checking-account/hero-free-checking-061124-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/free-checking-account/hero-free-checking-061124-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/free-checking-account/hero-free-checking-061124-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/free-checking-account/hero-free-checking-061124-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/free-checking-account/hero-free-checking-061124-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/free-checking-account/hero-free-checking-061124-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/gratis-cuenta-de-cheques"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-free-checking-061124.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "free-checking-hero",
    ...getHeroImgVariables(imgData),
    altText: "Grupo de amigos riendo en una playa mientras miran sus teléfonos móviles.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "*Cuenta de Cheques Gratuita Eleva Tu Banca"
          }
        },
        {
          id: 2,
          button: {
            id: "free-checking-hero-open-account-button",
            class: "btn-white",
            text: "Abrir una cuenta",
            url: "/open-an-account",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          tertiaryText: {
            class: "text-white",
            text: "*Cargos por fondos insuficientes pueden ser aplicados."
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      active: true,
      title: "Gratis Cuenta de cheques"
    }
  ];

  const debitCardData = {
    sectionId: "free-checking-debit-cards-section",
    depositText: "Con tan solo un depósito inicial de $25."
  };

  const freeCheckingVideoData = {
    vimeoId: "733695543",
    allowFeatures: "fullscreen",
    title: "Free Checking at WaFd Bank Offers No Minimum Balance or Monthly Fees | WaFd Bank",
    class: "m-auto iframe w-100 border-radius-12"
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Gratis Cuenta de Cheques</h1>
        <div className="row">
          <div className="col-md-6 mb-4 mb-md-0">
            <VimeoVideoFrame {...freeCheckingVideoData} />
          </div>
          <div className="col-md-6">
            <h2 className="text-success">Todo lo que necesitas y más, todo gratis</h2>
            <p>
              ¿Estás cansado de pagar cuotas mensuales por tu cuenta de cheques? ¡No busques más! Nuestra Cuenta de
              Cheques Gratuita ofrece una experiencia bancaria sin complicaciones,{" "}
              <span className="text-success font-weight-bold">
                sin requisitos de saldo mínimo y sin cuotas mensuales de mantenimiento
              </span>
              *. Ya sea que estés abriendo tu primera cuenta o buscando cambiar a una opción más rentable, nuestra
              Cuenta de Cheques Gratuita está diseñada para satisfacer tus necesidades.
            </p>
            <div className="row align-items-center mb-3">
              <div className="col-sm-auto col-md-12 col-lg-auto mb-3 mb-sm-0 mb-md-3 mb-lg-0">
                <Link
                  to="/es/banca-personal/cuenta-de-cheques#checking-overview-section"
                  id="checking-compare-accounts-link"
                  className="btn btn-link w-100 w-sm-auto"
                >
                  Compara cuentas corrientes
                </Link>
              </div>
              <div className="col-sm col-md col-lg">
                <Link to="/open-an-account" id="open-account-link" className="btn btn-primary w-100 w-sm-auto">
                  Abrir una cuenta
                </Link>
              </div>
            </div>
            <p id="free-checking-benefits-disclaimer" className="text-muted mb-0 text-sm">
              *Cargos por fondos insuficientes pueden ser aplicados.
            </p>
          </div>
        </div>
      </section>
      <FeaturesSection />
      <DebitCardsOpenAccount {...debitCardData} />
      <StartSavingsSection />
      <ServiceStatesOpenAccountSection />
      <ContactInfo bgClass="bg-white" isSpanish={true} />
    </SecondaryLanding>
  );
};
export default SpanishFreeChecking;
